<template>
  <ion-page id="entire-page" class="page scrollable customize-character-profile-page position-relative">
    <Transition>
      <div v-if="loading" class="loading-content position-absolute">
        <HeaderLoading />
      </div>
      <div v-else-if="character" class="content">
        <Header :character="character" :is-draft="isDraft" :template="template" :customize="currentTemplate" />
        <Actions v-if="hasSections" class="my-3" :sections-collapsed="sectionsCollapsed" :customize="currentTemplate" />
        <blab-card
          v-if="countPost"
          :user-post="userPosts"
          :count="countPost"
          :customize="currentTemplate"
          :template="template"
          :blabs-count="blabsCount"
          class="my-3"
          :character="true"
          :slug="character.slug"
        />
        <Sections :character="character" :customize="currentTemplate" :template="template" />
      </div>
    </Transition>
  </ion-page>
</template>

<script lang="ts" setup>
import Header from './components/Header.vue';
import Sections from './components/Sections.vue';
import Actions from './components/Actions.vue';
import HeaderLoading from './components/HeaderLoading.vue';
import BlabCard from '@/shared/components/BlabCard.vue';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { Character } from '@/shared/types/static-types';
import { unreorderableEditorKeys } from '@/shared/statics/constants';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { getPosts, getPostsCount } from '@/shared/actions/blabs';

const { character, loading, loadCharacterById, sectionsCollapsed, loadDraftCharacterById } = characterProfileStore();
const {
  loadCharacterStylize,
  customizeCharacter,
  currentTemplate,
  loadCharacterTemplate,
  previousTemplateData,
  template,
  loadTemplate,
  prevTemplate,
} = characterStylizeStore();

const emits = defineEmits(['close', 'data']);
const {
  isSavedToast,
  isCreatedToast,
  isTempApplied,
  user: loggedInUser,
  updateProfileTryOnAnimation,
  updateProfileTryOnBgImage,
} = authStore();
const router = useRouter();
const route = useRoute();
const tempCustomizeData = ref({});
const countPost = ref('');
const userPosts = ref([]);
const blabsCount = ref({});
const { width } = useWindowSize();
const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const characterWorlds = computed(() => {
  return get(character.value, 'worlds');
});

const isDraft = computed(() => {
  return route.name === 'character-draft-stylize';
});

const possibleSections = computed(() => {
  return omit(character?.value?.info, unreorderableEditorKeys as string[]);
});

const reorderedSections = computed(() => {
  const order: string[] = character?.value?.info?.order || Object.keys(possibleSections.value);
  const reorderedSections = order.reduce((acc: Character['info'], current) => {
    return {
      ...acc,
      [current]: possibleSections.value[current],
    };
  }, {} as Character['info']);

  return reorderedSections;
});

const hasSections = computed(() => {
  return Object.keys(reorderedSections.value).length > 0;
});
const latestTemplate = async () => {
  const val = get(route.query, 'tempId') as string;
  if (val) {
    await loadTemplate(val);
  }
};

const fetchPostsCount = async () => {
  if (!character.value) return;

  const resp = await getPostsCount({ character_in_post: character.value.id });
  blabsCount.value = resp;
};

const fetchUserPosts = async (page = 1) => {
  if (!character.value) return;
  const res = await getPosts(page, 2, {
    character: character.value.id,
    ordering: '-created',
  });
  countPost.value = res.count;
  userPosts.value = res.results;
};

const isCustomizeTabScreen = computed(() => {
  return width.value > 1184;
});
onMounted(async () => {
  updateProfileTryOnAnimation({});
  updateProfileTryOnBgImage({});
  if (isDraft.value) {
    const { id } = route.params;
    const idAsString = id as string;
    await loadDraftCharacterById(idAsString);
    if (character.value?.author?.username === loggedInUser.value.username) {
      await loadCharacterTemplate();
      await loadCharacterStylize(idAsString, 'characterdraft');
      if (!isEmpty(template.value)) await latestTemplate();
    } else {
      router.go(-1);
    }
    return;
  }

  const { id } = route.params;
  const idAsString = id as string;
  await loadCharacterById(idAsString);
  await loadCharacterStylize(idAsString, 'character');
  await fetchUserPosts();
  fetchPostsCount();
  if (character.value?.author?.username === loggedInUser.value.username) {
    await loadCharacterTemplate();
    if (!isEmpty(template.value)) await latestTemplate();
  } else {
    router.go(-1);
  }
});
watch(currentTemplate, async () => {
  if (get(route.query, 'tempId') && !isEmpty(currentTemplate.value)) {
    tempCustomizeData.value = cloneDeep(currentTemplate.value);
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (isSavedToast.value || isCreatedToast.value || isTempApplied.value) {
    next();
  } else if (
    (JSON.stringify(get(currentTemplate.value, 'data.theme')) !==
      JSON.stringify(get(customizeCharacter.value, 'data.theme')) &&
      !get(route.query, 'tempId')) ||
    (get(route.query, 'tempId') &&
      JSON.stringify(get(currentTemplate.value, 'data.theme')) !==
        JSON.stringify(get(tempCustomizeData.value, 'data.theme')))
  ) {
    if (!confirm('Are you sure you want to leave? You may lose unsaved changes.')) {
      next(false);
    } else {
      next();
    }
  } else {
    next();
  }
});
</script>

<style lang="sass" scoped>
.edit-theme-btn
  --background: #4d235e
.theme-edit-icon
  height: 25px
  width: 25px
.loading-content
  width: 100%
.customize-character-profile-page
  overflow: hidden
  padding: 24px 0 !important
  margin-top: -73px !important
  min-height: 628px
  @media(max-width:570px)
    margin-top: -51px !important
.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease

.v-enter-from,
.v-leave-to
  opacity: 0
.profile-section
  border-radius: 20px
  --background: #E6E6E6
  --ion-color-base: #E6E6E6
  --inner-padding-end: 15px !important
  --inner-padding-start: 5px !important
  --inner-padding-top: 5px !important
  --inner-padding-bottom: 5px !important
  --ion-color-base: #E6E6E6 !important
  font-size: 24px
  font-weight: 800
  color: #333333
  --background: #E6E6E6
  &.dark
    --ion-color-base: #17074C !important
    overflow: hidden
  .content
    background: #E6E6E6
    padding: 5px 22px 15px 22px
    font-size: 16px
    color: #333333
.dark .profile-section .content
  background: #17074C !important
.dark .edit-text
  color: black !important
</style>
